// MXLookup.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import QueryForm from '../QueryForm';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import HostRecord from '../HostRecord';
import { useClerk, useUser } from '@clerk/clerk-react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/features/store/store';
import { setUser, clearUser } from 'src/features/user/userSlice';

interface MXRecordBase {
  exchange: string;
  priority: number;
}

interface MXRecord extends MXRecordBase {
  ARecords: { address: string; ttl: number }[];
  AAAARecords: { address: string; ttl: number }[];
}

interface TestResults {
  dmarcPublished: boolean;
  dmarcPolicy: string;
  subdomainPolicy: string;
  aggregateReportEmail: string;
  forensicReportEmail: string;
  rawDMARCRecord: string;
}

const MXLookup: React.FC = () => {
  const { hostname } = useParams<{ hostname: string }>();
  const { openSignIn } = useClerk();
  const { isLoaded, isSignedIn, user } = useUser();
  const dispatch = useDispatch<AppDispatch>();
  const [mxRecords, setMxRecords] = useState<MXRecord[]>([]);
  const [testResults, setTestResults] = useState<TestResults>({
    dmarcPublished: false,
    dmarcPolicy: '',
    subdomainPolicy: '',
    aggregateReportEmail: '',
    forensicReportEmail: '',
    rawDMARCRecord: '',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isLoaded) return;

    if (!isSignedIn) {
      openSignIn({
        redirectUrl: `${window.location.origin}/mx-lookup/${hostname ?? ''}`,
      });
      return;
    }

    // Dispatch user info to Redux store if needed
    if (user) {
      const clientPrincipal = {
        userId: user.id,
        identityProvider: 'Clerk',
        userDetails: user.primaryEmailAddress?.emailAddress || '',
      };
      dispatch(setUser(clientPrincipal));
    } else {
      dispatch(clearUser());
    }

    async function fetchMXRecords() {
      setLoading(true);
      try {
        const [mxResponse, dmarcResponse, dnsResponse] = await Promise.all([
          fetch('/api/GetMXRecords', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ host: hostname }),
          }),
          fetch('/api/CheckDMARC', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ host: hostname }),
          }),
          fetch('/api/ResolveHostname', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ host: hostname }),
          }),
        ]);

        const mxData = await mxResponse.json();
        const dmarcData = await dmarcResponse.json();
        const dnsData = await dnsResponse.json();

        if (mxData && Array.isArray(mxData.mxRecords)) {
          const updatedMxRecords = mxData.mxRecords.map((record: MXRecordBase) => ({
            ...record,
            ARecords: dnsData.ARecords || [],
            AAAARecords: dnsData.AAAARecords || [],
          }));

          setMxRecords(updatedMxRecords);
          setTestResults({
            dmarcPublished: dmarcData.published || false,
            dmarcPolicy: dmarcData.policy || '',
            subdomainPolicy: dmarcData.subdomainPolicy || '',
            aggregateReportEmail: dmarcData.aggregateReportEmail || '',
            forensicReportEmail: dmarcData.forensicReportEmail || '',
            rawDMARCRecord: dmarcData.rawRecord || '',
          });
        } else {
          console.error('Received data is not in the expected format:', mxData);
        }
      } catch (error) {
        console.error('Failed to fetch MX records:', error);
      } finally {
        setLoading(false);
      }
    }

    if (hostname) {
      fetchMXRecords();
    }
  }, [isLoaded, isSignedIn, user, hostname, openSignIn, dispatch]);

  const pageTitle = hostname
    ? `MX Records Lookup for ${hostname} | NetQuery Tools`
    : 'MX Lookup Tool | Check MX Records for Any Domain - NetQuery Tools';
  const pageDescription = hostname
    ? `Perform an MX records lookup for ${hostname}. Analyze mail server configurations and ensure optimal email delivery with NetQuery Tools.`
    : 'Use our MX Lookup tool to find and analyze Mail Exchange (MX) records for any domain. Ensure your email routing is configured correctly for reliable email delivery.';

  if (!hostname) {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta
            name="keywords"
            content="MX Lookup, MX Records, Email Server Check, DNS Lookup, Mail Exchange Records, Email Delivery, Domain Email Configuration, NetQuery Tools"
          />
          <link rel="canonical" href="https://netquery.tools/mx-lookup/" />
        </Helmet>
        <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">
              <FontAwesomeIcon icon={faAt} className="text-blue-500" /> MX Records Lookup Tool
            </h1>
            <QueryForm
              placeholder="Enter a domain..."
              ariaLabel="Enter a domain for MX lookup"
              navigatePath={(inputValue) => `/mx-lookup/${inputValue}`}
            />
            <p className="mt-2 text-md text-gray-600">
              Use NetQuery Tools' MX Lookup to quickly find and analyze Mail Exchange (MX) records for any domain. Ensure your domain's email routing is correctly configured for optimal email delivery and performance.
            </p>
            <section className="mt-6">
              <h2 className="text-2xl font-semibold text-gray-800">Why Use Our MX Lookup Tool?</h2>
              <p className="mt-2 text-md text-gray-600">
                Understanding your domain's MX records is crucial for managing email flow and ensuring that emails reach their intended recipients without delay. Our tool provides detailed insights into MX record configurations, including priority levels and associated mail servers, helping you troubleshoot issues and verify setups.
              </p>
            </section>

            <section className="mt-6">
              <h2 className="text-2xl font-semibold text-gray-800">How Does It Work?</h2>
              <p className="mt-2 text-md text-gray-600">
                Simply enter the domain name you wish to check in the form above. Our tool queries DNS records in real-time and displays the MX records associated with the domain, including priority and server details. Use this information to verify your MX records are correctly set up or to diagnose mail delivery issues.
              </p>
            </section>

            <section className="mt-6">
              <h2 className="text-2xl font-semibold text-gray-800">Benefits of Regular MX Record Checks</h2>
              <ul className="list-disc pl-10 text-md text-gray-600 mt-2">
                <li>Ensure reliable email delivery by verifying MX configurations.</li>
                <li>Diagnose and troubleshoot email delivery issues promptly.</li>
                <li>Verify that email services are correctly configured for new or migrated domains.</li>
                <li>Enhance email security by confirming that MX records point to the correct mail servers.</li>
                <li>Optimize email routing for better performance and reliability.</li>
              </ul>
            </section>
            <div className="max-w-xl lg:max-w-4xl mx-auto mt-12">
              <h2 className="text-4xl font-semibold text-gray-800 mb-6">Frequently Asked Questions</h2>
              <dl className="text-left space-y-4">
                <div>
                  <dt className="font-medium text-gray-800">What is an MX record?</dt>
                  <dd className="text-gray-600 mt-1">
                    An MX (Mail Exchange) record is a type of DNS record that specifies the mail server responsible for receiving email messages on behalf of a domain.
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-800">How do MX records affect email delivery?</dt>
                  <dd className="text-gray-600 mt-1">
                    MX records direct email messages to your mail servers. Incorrect MX records can lead to email delivery failures or delays, making it essential to configure them properly.
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-800">Can a domain have multiple MX records?</dt>
                  <dd className="text-gray-600 mt-1">
                    Yes, a domain can have multiple MX records with different priority levels. This setup provides redundancy, ensuring email delivery even if one server fails.
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-800">How can I check if my MX records are correct?</dt>
                  <dd className="text-gray-600 mt-1">
                    Use our MX Lookup tool to retrieve your domain's MX records. Verify that the mail servers listed are correct and that the priority levels are set as intended.
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-800">Why are my emails not being delivered?</dt>
                  <dd className="text-gray-600 mt-1">
                    There could be several reasons, including misconfigured MX records, DNS propagation delays, or issues with the mail server. Checking your MX records is a good first step in troubleshooting.
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-800">How often should I check my MX records?</dt>
                  <dd className="text-gray-600 mt-1">
                    It's advisable to check your MX records whenever you make changes to your email server configurations or DNS settings, and periodically to ensure everything remains correctly configured.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={`Perform an MX records lookup for ${hostname}. Analyze mail server configurations and ensure optimal email delivery with NetQuery Tools.`}
        />
        <meta
          name="keywords"
          content={`MX Records for ${hostname}, MX Lookup, Email Server Check, DNS Lookup, Mail Exchange Records, Email Delivery, Domain Email Configuration, NetQuery Tools`}
        />
        <link rel="canonical" href={`https://netquery.tools/mx-lookup/${hostname}`} />
      </Helmet>
      <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            <FontAwesomeIcon icon={faAt} className="text-blue-500" /> MX Records for <HostRecord host={hostname} />
          </h1>
          {loading ? (
            <div className="flex justify-center items-center space-x-2">
              <FontAwesomeIcon icon={faSpinner} spin className="text-2xl text-blue-500" />
              <p className="text-lg text-gray-600">Loading MX records...</p>
            </div>
          ) : (
            <>
              <div className="my-6">
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">MX Records Details for {hostname}</h2>
                <p className="text-md text-gray-600">
                  Below are the Mail Exchange (MX) records for <HostRecord host={hostname} />. These records determine how emails are routed for your domain.
                </p>
                <ul className="list-disc pl-5 text-md text-gray-600 mt-2">
                  {mxRecords.map((record, index) => (
                    <li key={index}>
                      <strong>Exchange:</strong> <HostRecord host={record.exchange} /> (Priority: {record.priority})
                    </li>
                  ))}
                </ul>
              </div>
              <div className="my-6">
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">DMARC Analysis for {hostname}</h2>
                <p className="text-md text-gray-600">
                  DMARC (Domain-based Message Authentication, Reporting, and Conformance) helps prevent email spoofing. Below is the DMARC record analysis for your domain.
                </p>
                <ul className="list-disc pl-5 text-md text-gray-600 mt-2">
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={testResults.dmarcPublished ? faCheckCircle : faTimesCircle}
                      className={`mr-2 ${testResults.dmarcPublished ? 'text-green-500' : 'text-red-500'}`}
                    />
                    DMARC Record Published: {testResults.dmarcPublished ? 'Yes' : 'No'}
                  </li>
                  <li>
                    <strong>DMARC Policy:</strong> {testResults.dmarcPolicy || 'Not specified'}
                  </li>
                  <li>
                    <strong>Subdomain Policy:</strong> {testResults.subdomainPolicy || 'Not specified'}
                  </li>
                  <li>
                    <strong>Aggregate Report Email:</strong> {testResults.aggregateReportEmail || 'Not specified'}
                  </li>
                  <li>
                    <strong>Forensic Report Email:</strong> {testResults.forensicReportEmail || 'Not specified'}
                  </li>
                  <li>
                    <strong>Raw DMARC Record:</strong> {testResults.rawDMARCRecord || 'Not specified'}
                  </li>
                </ul>
              </div>
              <div className="my-6">
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">Next Steps</h2>
                <p className="text-md text-gray-600">
                  If you notice any issues with your MX or DMARC records, consider updating your DNS settings or consulting with your email service provider to enhance email delivery and security.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MXLookup;