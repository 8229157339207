import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShieldAlt,
  faAt,
  faNetworkWired,
  faEnvelopeOpenText,
  faIdBadge,
  faKey,
  faSearch,
  faSyncAlt,
  faInfoCircle,
  faExchangeAlt,
  faUserSecret,
  faGlobeAmericas,
  faBan,
  faServer,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';

const FeatureOverview = () => {
    return (
        <div className="bg-white text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
                <div id="tools" className="flex flex-wrap -m-4">
                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faShieldAlt} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/cert">TLS Certificate and Cipher Analysis</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Analyze your site's TLS certificates and cipher suites to ensure robust SSL/TLS security.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faAt} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/mx-lookup">MX Records Lookup and Verification</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Check and verify your domain's MX records to ensure proper email server configurations and reliable email delivery.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faServer} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/dns">Authoritative DNS Server Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Identify your domain's authoritative DNS servers and retrieve accurate DNS records.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faEnvelopeOpenText} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                               <Link to="/spf">SPF Record Checker and Validator</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Inspect and validate your SPF (Sender Policy Framework) records to prevent email spoofing and enhance email deliverability.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faIdBadge} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/bimi">BIMI Record Verification Tool</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Verify your BIMI (Brand Indicators for Message Identification) records to reinforce brand identity in email clients.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faKey} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/dkim">DKIM Record Checker and Validator</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Examine your DKIM (DomainKeys Identified Mail) records to secure emails against tampering and ensure message integrity.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faSearch} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/validate-dns">DNS Configuration Validator</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Validate your DNS settings to ensure they are correct, optimized, and comply with best practices.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faSyncAlt} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/ptr">Reverse DNS (PTR) Record Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Perform reverse DNS lookups to map IP addresses back to hostnames using PTR records.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/asn">ASN Lookup and Information</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Retrieve detailed information on Autonomous System Numbers (ASN) and their associated networks.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faExchangeAlt} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/asntoip">ASN to IP Range Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Discover all IP address ranges associated with a specific Autonomous System Number (ASN).
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faUserSecret} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/whois">Domain Whois Information Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Access comprehensive domain registration details and ownership information through Whois lookup.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faGlobeAmericas} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/country">IP Address Geolocation</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Identify the geographical location and country of origin for any IP address.
                            </p>
                        </div>
                    </div>

                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faBan} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/blacklist">DNS Blacklist (DNSBL) Lookup</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Check if your domain or IP is listed on DNS-based blacklists (DNSBL) to prevent email delivery issues.
                            </p>
                        </div>
                    </div>
                    
                    <div className="p-4 md:w-1/3 w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                            <FontAwesomeIcon icon={faNetworkWired} className="text-blue-500" />
                            <h2 className="text-gray-900 font-medium title-font tracking-widest text-sm mb-3">
                                <Link to="/address">Domain to IP Address Resolution</Link>
                            </h2>
                            <p className="leading-relaxed text-base">
                                Resolve and retrieve the IP address records (A and AAAA records) associated with a domain.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default FeatureOverview;
