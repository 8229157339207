import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './styles.css';
import { validateBIMIRecord, validateLogo } from './utils';
import Loading from '../Loading';
import { Helmet } from 'react-helmet';
import QueryForm from '../QueryForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdBadge } from '@fortawesome/free-solid-svg-icons';
import { useClerk, useUser } from '@clerk/clerk-react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/features/store/store';
import { setUser, clearUser } from 'src/features/user/userSlice';

interface BIMIRecord {
  record: string;
}

interface BIMIResponse {
  bimiRecords: string[];
}

interface DMARCData {
  published: boolean;
  policy: string;
  subdomainPolicy: string;
  aggregateReportEmail: string;
  forensicReportEmail: string;
  rawRecord: string;
}

const BIMILookup: React.FC = () => {
  const { hostname } = useParams<{ hostname: string }>();
  const [searchHostname, setSearchHostname] = useState<string | undefined>(hostname);
  const navigate = useNavigate();
  const [bimiRecord, setBIMIRecord] = useState<BIMIRecord | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [logoWarning, setLogoWarning] = useState<string[]>([]);
  const [dmarcData, setDmarcData] = useState<DMARCData | null>(null);
  const { openSignIn } = useClerk();
  const { isLoaded, isSignedIn, user } = useUser();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!isLoaded) return;

    if (!isSignedIn) {
      openSignIn({
        redirectUrl: `${window.location.origin}/bimi/${hostname ?? ''}`,
      });
      return;
    }

    if (user) {
      const clientPrincipal = {
        userId: user.id,
        identityProvider: 'Clerk',
        userDetails: user.primaryEmailAddress?.emailAddress || '',
      };
      dispatch(setUser(clientPrincipal));
    } else {
      dispatch(clearUser());
    }
  }, [isLoaded, isSignedIn, user, hostname, openSignIn, dispatch]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const fetchBIMIRecord = useCallback(async () => {
    try {
      if (hostname) {
        const response = await fetch('/api/GetBIMIRecord', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ host: hostname }),
        });

        if (response.status === 204) {
          setBIMIRecord(null);
          setImageUrl(null);
          setLogoWarning([]);
          setDarkMode(false);
        } else {
          const data: BIMIResponse = await response.json();

          if (data && Array.isArray(data.bimiRecords) && data.bimiRecords.length > 0) {
            const record = data.bimiRecords[0];

            if (validateBIMIRecord(record)) {
              setBIMIRecord({ record });

              const match = record.match(/l=([^;]+)/);
              if (match) {
                setImageUrl(match[1]);
              }
            } else {
              console.error('Invalid BIMI record:', record);
            }
          } else {
            console.error('Received data is not in the expected format:', data);
          }
        }
      }
    } catch (error) {
      console.error('Failed to fetch BIMI record:', error);
    } finally {
      setLoading(false);
    }
  }, [hostname]);

  const fetchDMARCData = useCallback(async () => {
    try {
      const response = await fetch('/api/CheckDMARC', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ host: hostname }),
      });

      if (response.ok) {
        const data: DMARCData = await response.json();
        setDmarcData(data);
      } else {
        setDmarcData(null);
      }
    } catch (error) {
      console.error('Failed to fetch DMARC data:', error);
    }
  }, [hostname]);

  useEffect(() => {
    fetchBIMIRecord();
    fetchDMARCData();
  }, [hostname, fetchBIMIRecord, fetchDMARCData]);

  useEffect(() => {
    const checkLogoValidity = async () => {
      if (imageUrl) {
        const { isValid, reasons, warnings } = await validateLogo(imageUrl);
        if (!isValid) {
          console.error('Invalid logo:', imageUrl, reasons);
          setLogoWarning(reasons);
        } else {
          setLogoWarning(warnings);
        }
      }
    };
    checkLogoValidity();
  }, [imageUrl]);

  const handleSearch = () => {
    if (searchHostname !== hostname) {
      navigate(`/bimi/${searchHostname}`);
    }
  };

  const handleRefresh = () => {
    setLoading(true);
    fetchBIMIRecord();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchHostname(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const downloadLogo = () => {
    if (imageUrl) {
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = `${hostname}-bimi-logo.svg`;
      link.click();
    }
  };

  const pageTitle = hostname
    ? `BIMI Lookup for ${hostname} - Check, Create & Setup BIMI Record | NetQuery Tools`
    : "Comprehensive BIMI Record Check & Setup Guide | NetQuery Tools";
  const pageDescription =
    "Effortlessly identify, create, and set up your BIMI record with NetQuery Tools. Ensure your brand's logo appears in customers' inboxes accurately.";

  if (!hostname) {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="keywords" content="BIMI record lookup, create BIMI record, setup BIMI record, add BIMI record, BIMI record check, BIMI record example, how to BIMI record, BIMI record guide, BIMI record for domain, BIMI record tool, BIMI DNS record, BIMI record validator, BIMI record generator, BIMI record Office 365, BIMI record Google Workspace, BIMI record Cloudflare" />
          <link rel="canonical" href={`https://netquery.tools/bimi/${hostname ? hostname : ''}`} />
        </Helmet>
        <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">
              <FontAwesomeIcon icon={faIdBadge} className="text-red-500" /> BIMI Records Checker & Setup Guide
            </h1>
            <p className="mb-4">Discover how to find, verify, and configure your BIMI records to enhance your brand's presence in your customers' inboxes.</p>
            <QueryForm placeholder="Enter a domain..." ariaLabel="Enter a domain for finding or setting up your BIMI record" navigatePath={(inputValue) => `/bimi/${inputValue}`} />
            <div className="mt-8">
              <h2 className="text-2xl font-bold text-gray-800">Frequently Asked Questions</h2>
              <dl className="mt-4">
                <dt className="text-lg font-semibold text-gray-900">What is a BIMI record?</dt>
                <dd className="mt-2">A BIMI (Brand Indicators for Message Identification) record allows brands to display their logo in supported email clients, enhancing brand recognition and trust.</dd>
                <dt className="mt-4 text-lg font-semibold text-gray-900">How do I create a BIMI record?</dt>
                <dd className="mt-2">To create a BIMI record, you must first ensure you have a DMARC policy in place. Then, create a SVG version of your logo and host it on a secure, publicly accessible URL. Finally, add a BIMI DNS record pointing to this URL.</dd>
                <dt className="mt-4 text-lg font-semibold text-gray-900">How can I check my BIMI record?</dt>
                <dd className="mt-2">Use NetQuery Tools' BIMI Lookup tool by entering your domain name. It will check if a BIMI record exists for your domain and show the linked logo if available.</dd>
                <dt className="mt-4 text-lg font-semibold text-gray-900">What are common issues with BIMI record setup?</dt>
                <dd className="mt-2">Common issues include not having a DMARC policy, using an unsupported logo format, or having the logo hosted at an inaccessible URL. Ensure your logo is SVG format and publicly accessible.</dd>
              </dl>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content="BIMI record lookup, create BIMI record, setup BIMI record, add BIMI record, BIMI record check, BIMI record example, how to BIMI record, BIMI record guide, BIMI record for domain, BIMI record tool, BIMI DNS record, BIMI record validator, BIMI record generator, BIMI record Office 365, BIMI record Google Workspace, BIMI record Cloudflare" />
        <link rel="canonical" href={`https://netquery.tools/bimi/${hostname ? hostname : ''}`} />
      </Helmet>
      <div className="bimi-container">
        <h2 className="bimi-title">BIMI Record Lookup</h2>
        <div className="bimi-input">
          <label htmlFor="hostname" className="bimi-label">Hostname:</label>
          <span className="bimi-value">{hostname}</span>
        </div>
        {loading ? (
          <Loading /> // Use the Loading component here
        ) : (
          <>
            <p className="bimi-value">{bimiRecord?.record || 'No BIMI record found.'}</p>
            {logoWarning.length > 0 && (
              <div className="bimi-logo-warning">
                <p>Logo Warnings:</p>
                <ul>
                  {logoWarning.map((warning, index) => (
                    <li key={index}>{warning}</li>
                  ))}
                </ul>
              </div>
            )}
            {imageUrl && (
              <>
                <div className={`bimi-logo-container${darkMode ? ' dark-mode' : ''}`}>
                  <img
                    className={`bimi-logo bimi-logo-square${darkMode ? ' dark-mode' : ''}`}
                    src={imageUrl}
                    alt="BIMI logo square"
                  />
                  <img
                    className={`bimi-logo bimi-logo-circle${darkMode ? ' dark-mode' : ''}`}
                    src={imageUrl}
                    alt="BIMI logo circle"
                  />
                  <img
                    className={`bimi-logo bimi-logo-squircle${darkMode ? ' dark-mode' : ''}`}
                    src={imageUrl}
                    alt="BIMI logo squircle"
                  />
                </div>
                <button className="bimi-download-button" onClick={downloadLogo}>
                  Download Logo
                </button>
                <button className="bimi-toggle-dark-mode" onClick={toggleDarkMode}>
                  Toggle Dark Mode
                </button>
              </>
            )}
            {dmarcData && (
              <div className="dmarc-container">
                <h3>DMARC Information</h3>
                <ul>
                  <li>DMARC Record Published: {dmarcData.published ? "Status Ok" : "Not Found"}</li>
                  <li>DMARC Policy: {dmarcData.policy || "Not specified"}</li>
                  <li>DMARC Subdomain Policy: {dmarcData.subdomainPolicy || "Not specified"}</li>
                  <li>Aggregate Report Email: {dmarcData.aggregateReportEmail || "Not specified"}</li>
                  <li>Forensic Report Email: {dmarcData.forensicReportEmail || "Not specified"}</li>
                  <li>Raw DMARC Record: {dmarcData.rawRecord || "Not specified"}</li>
                </ul>
              </div>
            )}
            <div className="bimi-search">
              <div className="bimi-search-input-wrapper">
                <input
                  type="text"
                  className="input"
                  value={searchHostname}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter hostname to search"
                />
              </div>
              <div className="bimi-search-button-wrapper">
                <button className="bimi-download-button bimi-search-button" onClick={handleSearch}>Search</button>
              </div>
            </div>
            <button className="bimi-download-button bimi-refresh-button" onClick={handleRefresh}>Refresh</button>
          </>
        )}
      </div>
    </>
  );
};

export default BIMILookup;
