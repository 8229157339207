import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Auth from '../Auth';
import { RootState } from 'src/features/store/store';

const navigation = [
  { name: 'About', href: '/about', current: false },
  { name: 'MX Lookup', href: '/mx-lookup', current: false },
  { name: 'Experimental', href: '/experimental', current: false },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export default function CustomNavbar() {
  const [ipAddress, setIpAddress] = useState<string | null>(null);
  const userInfo = useSelector((state: RootState) => state.user.data);

  useEffect(() => {
    const fetchIPAddress = async () => {
      if (userInfo) {
        try {
          const response = await fetch("/api/GetVisitorInfo", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
          });
          const data = await response.json();
          setIpAddress(data.ipAddress);
        } catch (error) {
          console.error('Error fetching IP address:', error);
        }
      } else {
        setIpAddress(null);
      }
    };
    fetchIPAddress();
  }, [userInfo]);

  return (
    <Disclosure as="nav" className="bg-gradient-to-r from-sky-500 to-sky-400 text-white shadow-md">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start">
                <div className="">
                  <Link to="/" className="flex title-font font-medium text-white mb-0 hover:no-underline">
                    <img src="/logo192.png" alt="logo" className="w-12 h-12" />
                    <span className="ml-3 text-3xl">NetQuery</span>
                  </Link>
                </div>
                <div className="hidden lg:block lg:ml-10">
                  <div className="flex space-x-4 mt-1">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current ? 'bg-yellow-400' : 'hover:bg-yellow-400',
                          'text-white px-3 py-2 rounded-md text-lg font-medium text-nowrap hover:no-underline'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}

                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 items-center pr-2 hidden lg:flex">
                {ipAddress && (
                  <Link to={`/asn/${ipAddress}`} className="px-3 py-2 rounded-md text-lg font-medium font-mono text-white hover:bg-blue-700 text-nowrap hover:no-underline">
                    🌐{ipAddress}
                  </Link>
                )}
                <Auth redirect={`${window.location.href}`} />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={Link}
                  to={item.href}
                  className={classNames(
                    item.current ? 'bg-blue-600' : 'hover:bg-blue-700',
                    'text-white block px-3 py-2 rounded-md text-base font-medium hover:no-underline'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              {ipAddress && (
                <Disclosure.Button
                  as={Link}
                  to={`/asn/${ipAddress}`}
                  className="block px-3 py-2 rounded-md text-base font-medium font-mono text-white hover:bg-blue-700 hover:no-underline"
                >
                  🌐{ipAddress}
                </Disclosure.Button>
              )}
              <Auth redirect={`${window.location.href}`} />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}