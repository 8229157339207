import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import QueryForm from '../QueryForm';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { useClerk, useUser } from '@clerk/clerk-react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/features/store/store';
import { setUser, clearUser } from 'src/features/user/userSlice';

interface BlacklistResult {
  blacklist: string;
  isListed: boolean;
  errorCode?: string;
  listingType?: string;
}

interface BlacklistCheckResult {
  ip: string;
  results: BlacklistResult[];
}

interface BlacklistInfoProps {}

const BlacklistInfo: React.FC<BlacklistInfoProps> = () => {
  const { ipAddress } = useParams<{ ipAddress: string }>();
  const { openSignIn } = useClerk();
  const { isLoaded, isSignedIn, user } = useUser();
  const dispatch = useDispatch<AppDispatch>();
  const [blacklistResults, setBlacklistResults] = useState<BlacklistCheckResult[]>([]);

  useEffect(() => {
    if (!isLoaded) return;

    if (!isSignedIn) {
      openSignIn({
        redirectUrl: `${window.location.origin}/blacklist/${ipAddress ?? ''}`,
      });
      return;
    }

    // Set or clear user in Redux
    if (user) {
      const clientPrincipal = {
        userId: user.id,
        identityProvider: 'Clerk',
        userDetails: user.primaryEmailAddress?.emailAddress || '',
      };
      dispatch(setUser(clientPrincipal));
    } else {
      dispatch(clearUser());
    }

    const fetchData = async () => {
      try {
        const response = await fetch('/api/GetBlacklistInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ipAddresses: [ipAddress] }),
        });

        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }

        const jsonResponse = await response.json();
        setBlacklistResults(jsonResponse.blacklistResults);
      } catch (error) {
        console.error(error);
      }
    };

    if (ipAddress) {
      fetchData();
    }
  }, [isLoaded, isSignedIn, user, ipAddress, openSignIn, dispatch]);

  const renderResult = (blacklistResult: BlacklistResult) => {
    const listedClass = blacklistResult.isListed ? 'text-red-600' : 'text-green-600';
    if (blacklistResult.errorCode) {
      return <span className="text-red-600">Error: {blacklistResult.errorCode}</span>;
    }
    if (blacklistResult.isListed) {
      return <span className={listedClass}>Listed - {blacklistResult.listingType}</span>;
    }
    return <span className={listedClass}>Not Listed</span>;
  };

  const pageTitle = ipAddress ? `Blacklist check for ${ipAddress} - IP Blacklist Check Tool | NetQuery Tools` : "IP Blacklist Check Tool | NetQuery Tools";
  const pageDescription = "Identify if your IP is blacklisted and maintain network integrity using NetQuery Tools' comprehensive IP blacklist check tool.";

  if (!ipAddress) {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="keywords" content="IP Blacklist Check, IP Reputation, Spamhaus Check, IP Address Monitoring" />
          <link rel="canonical" href={`https://netquery.tools/blacklist${ipAddress ? `/${ipAddress}` : ''}`} />
        </Helmet>
        <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faBan} className="text-red-500" /> Blacklist IP Check</h1>
            <p className="mb-4">Check if your IP address is blacklisted and secure your network with NetQuery Tools.</p>
            <QueryForm placeholder="Enter an IP address..." ariaLabel="Enter an IP address for a blacklist check" navigatePath={(inputValue) => `/blacklist/${inputValue}`} />
            {/* Additional informational sections */}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content="Blacklist, Network Security, IP Reputation, Blacklist Monitoring" />
        <link rel="canonical" href="https://netquery.tools/blacklist" />
      </Helmet>
      <div className="container mx-auto p-4 bg-white shadow-lg rounded-md">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Blacklist Information for {ipAddress}</h2>
        {blacklistResults.map((result, index) => (
          <div key={index} className="mb-6 p-4 border-b last:border-b-0">
            <div className="mb-2">
              <label htmlFor="ip-address" className="block text-sm font-medium text-gray-700">
                IP Address:
              </label>
              <span className="text-lg font-semibold text-gray-800">{result.ip}</span>
            </div>
            {result.results.map((blacklistResult, idx) => (
              <div key={idx} className="mb-2">
                <label htmlFor={`blacklist-${idx}`} className="block text-sm font-medium text-gray-700">
                  {blacklistResult.blacklist}:
                </label>
                <span className="text-lg font-semibold">
                  {renderResult(blacklistResult)}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default BlacklistInfo;