import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from '../Loading';
import { Helmet } from 'react-helmet';
import { useClerk } from '@clerk/clerk-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/features/store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';

interface DKIMRecord {
  selector: string;
  provider: string;
  published: boolean;
  keyType?: string;
  publicKey?: string;
  keyLength?: number;
}

interface DKIMResponse {
  published: boolean;
  keyType?: string;
  publicKey?: string;
}

interface SelectorProviderMap {
  [selector: string]: string;
}

// Move selectorProviderMap outside the component
const selectorProviderMap: SelectorProviderMap = {
  'selector1': 'Microsoft 365', 'selector2': 'Microsoft 365', 'google': 'Google', 'mail': 'Custom',
  'smtp': 'Custom', 'office365': 'Microsoft 365', 'k1': 'Klaviyo', 'k2': 'Klaviyo',
  'protonmail1': 'ProtonMail', 'protonmail2': 'ProtonMail', 's1': 'SendGrid', 's2': 'SendGrid',
  '200608': 'Salesforce Marketing Cloud', 'gsuite': 'Google Workspace', 'googleapps': 'Google Workspace',
  'ms': 'Microsoft 365', 'sendgrid': 'SendGrid', 'sg': 'SendGrid', 'mailgun': 'Mailgun',
  'mg': 'Mailgun', 'ses': 'Amazon SES', 'amazonses': 'Amazon SES', 'postmark': 'Postmark',
  'pm': 'Postmark', 'mandrill': 'Mandrill', 'md': 'Mandrill', 'sparkpost': 'SparkPost',
  'sp': 'SparkPost', 'zoho': 'Zoho Mail', 'zm': 'Zoho Mail', 'domainkey': 'Custom',
  'dkim': 'Custom', 'email': 'Custom', 'key': 'Custom', 'mx': 'Custom', '2020': 'Custom',
  '2021': 'Custom', '2022': 'Custom', '2023': 'Custom', 'x': 'Custom', 'y': 'Custom',
  'z': 'Custom', 'primary': 'Custom', 'secondary': 'Custom',
  'o365': 'Microsoft 365', 'gs': 'Google Suite', 'gw': 'Google Workspace',
  'smtp1': 'Custom', 'smtp2': 'Custom', 'mail1': 'Custom', 'mail2': 'Custom',
  'k3': 'Klaviyo', 'k4': 'Klaviyo', 'dk': 'Custom', 'dk1': 'Custom', 'dk2': 'Custom',
  'api': 'Custom', 'mta': 'Custom', 'mta1': 'Custom', 'mta2': 'Custom',
  'sendinblue': 'Sendinblue', 'sib': 'Sendinblue', 'campaign': 'Custom',
  'marketing': 'Custom', 'news': 'Newsletter', 'newsletter': 'Newsletter',
  'aweber': 'Aweber', 'aw': 'Aweber', 'constantcontact': 'Constant Contact',
  'cc': 'Constant Contact', 'getresponse': 'GetResponse', 'gr': 'GetResponse',
  'mailchimp': 'Mailchimp', 'mc': 'Mailchimp', 'activecampaign': 'ActiveCampaign',
  'ac': 'ActiveCampaign', 'convertkit': 'ConvertKit', 'ck': 'ConvertKit',
  'infusionsoft': 'Infusionsoft', 'is': 'Infusionsoft', 'klaviyo': 'Klaviyo',
  'kv': 'Klaviyo', 'drip': 'Drip', 'dp': 'Drip', 'benchmark': 'Benchmark Email',
  'bm': 'Benchmark Email', 'hubspot': 'HubSpot', 'hs': 'HubSpot',
  'icontact': 'iContact', 'ic': 'iContact', 'verticalresponse': 'VerticalResponse',
  'vr': 'VerticalResponse', 'mailerlite': 'MailerLite', 'ml': 'MailerLite',
  'moosend': 'Moosend', 'msd': 'Moosend', 'ontraport': 'Ontraport', 'op': 'Ontraport', '10dkim1': 'Other', '11dkim1': 'Other'
};

const DKIMLookup: React.FC = () => {
  const { domain: urlDomain, selector: urlSelector } = useParams<{ domain?: string; selector?: string }>();
  const { openSignIn } = useClerk();
  const userInfo = useSelector((state: RootState) => state.user.data);
  const domainRef = useRef<HTMLInputElement>(null);
  const selectorRef = useRef<HTMLInputElement>(null);
  const [dkimRecords, setDKIMRecords] = useState<DKIMRecord[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (urlDomain) {
      if (!userInfo) {
        openSignIn({
          redirectUrl: `${window.location.origin}/dkim/${urlDomain}`,
        });
        return;
      }

      const fetchDKIMRecord = async (selector: string) => {
        try {
          const response = await fetch('/api/CheckDKIM', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ host: urlDomain, selector }),
          });
          const data: DKIMResponse = await response.json();
          if (data && data.published) {
            const keyLength = calculateBitLength(data.publicKey ?? '');
            const provider = selectorProviderMap[selector] || 'Custom';
            return { ...data, keyLength, selector, provider, published: data.published };
          }
        } catch (error) {
          console.error('Failed to fetch DKIM record:', error);
        }
        return null;
      };

      const manualSelector = urlSelector || selectorRef.current?.value;

      if (manualSelector) {
        fetchDKIMRecord(manualSelector).then(record => {
          if (record) {
            setDKIMRecords([{ ...record, provider: selectorProviderMap[manualSelector] || 'Custom' }]);
          } else {
            setDKIMRecords([]);
          }
          setLoading(false);
        });
      } else {
        const checkSelectors = async () => {
          setLoading(true);

          const fetchPromises = Object.keys(selectorProviderMap).map(async (selector) => {
            try {
              const record = await fetchDKIMRecord(selector);
              if (record) {
                return { ...record, provider: selectorProviderMap[selector] } as DKIMRecord;
              }
              return null;
            } catch (error) {
              console.error(`Error fetching DKIM record for selector ${selector}:`, error);
              return null;
            }
          });

          const results = await Promise.all(fetchPromises);
          const records = results.filter((record): record is DKIMRecord => record !== null);
          setDKIMRecords(records);
          setLoading(false);
        };

        checkSelectors();
      }
    } else {
      setLoading(false);
    }
  // Remove selectorProviderMap from dependency array
  }, [urlDomain, urlSelector, userInfo, openSignIn]);

  const calculateBitLength = (publicKey: string): number => publicKey.replace(/=/g, '').length * 0.75 * 8;

  const handleSearch = () => {
    const domain = domainRef.current?.value || '';
    const selector = selectorRef.current?.value || '';
    setLoading(true);
    setDKIMRecords([]);
    if (selector) {
      navigate(`/dkim/${domain}/${selector}`);
    } else {
      navigate(`/dkim/${domain}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>DKIM Checker - Verify Your Email Authentication</title>
        <meta name="description" content="Use our DKIM Checker to verify a domain's DKIM records without knowing the selector, improve email deliverability, and protect against email spoofing. Ensure your emails are trusted and secure." />
        <meta name="keywords" content="DKIM Checker, Email Authentication, DKIM Record Verification, Email Security, DKIM Lookup, Prevent Email Spoofing, DKIM Validation, DomainKeys Identified Mail, Check DKIM Record, DKIM Signature" />
        <link rel="canonical" href={`https://netquery.tools/dkim/${urlDomain ? urlDomain && (urlSelector ? `/${urlSelector}` : '') : ''}`} />
      </Helmet>
      <div className="max-w-4xl mx-auto p-4">
        <h1 className="text-4xl font-bold text-gray-900 mb-0"><FontAwesomeIcon icon={faKey} className="text-blue-500" /> DKIM Records Tool</h1>
        <h3 className="mb-6">Leave the selector blank to automatically search common DKIM selectors.</h3>
        <div className="space-y-4">
          <div>
            <label htmlFor="domain" className="block text-sm font-medium text-gray-700">Domain:</label>
            <input
              type="text"
              name="domain"
              defaultValue={urlDomain || ''}
              ref={domainRef}
              placeholder="Enter domain"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label htmlFor="selector" className="block text-sm font-medium text-gray-700">Selector:</label>
            <input
              type="text"
              name="selector"
              defaultValue={urlSelector || ''}
              ref={selectorRef}
              placeholder="Enter selector (optional)"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="flex justify-end">
            <button onClick={handleSearch} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Search
            </button>
          </div>
        </div>
        {urlDomain ? loading ? (
          <Loading />
        ) : dkimRecords.length > 0 ? (
          dkimRecords.map((record, index) => (
            <div
              key={index}
              className="mt-6 bg-white p-6 border border-gray-200 rounded-lg shadow-sm space-y-2 text-sm text-gray-700 font-semibold"
            >
              <p>Selector: <span className="font-normal">{record.selector}</span></p>
              <p>Provider: <span className="font-normal">{record.provider}</span></p>
              <p>Key Type: <span className="font-normal">{record.keyType || 'rsa'}</span></p>
              <div className="break-words">
                <span className="font-semibold">Public Key:</span> <br />
                <span className='font-mono text-xs bg-gray-100 rounded break-words'>{record.publicKey}</span>
              </div>
              <p>Key Length: <span className="font-normal">{record.keyLength || 'N/A'}</span></p>
            </div>
          ))

        ) : (
          <p className="mt-4 text-gray-500">No DKIM records found for the tested selectors.</p>
        ) : <div className="mt-4 text-gray-500">
          <h2 className="text-xl font-bold mb-4">Frequently Asked Questions about DKIM Checker</h2>
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold text-lg">What is a DKIM Checker?</h3>
              <p className="text-gray-700">A DKIM Checker is a specialized online tool that verifies the validity and configuration of a domain's DKIM (DomainKeys Identified Mail) records. These records are essential for email authentication, helping to improve email deliverability and protect against email spoofing by verifying that the email has not been altered in transit.</p>
            </div>
            <div>
              <h3 className="font-semibold text-lg">Common DKIM Selectors Explained</h3>
              <p className="text-gray-700">Various services suggest default selectors, with <em>selector2</em> being a popular choice for Microsoft 365 users. Leveraging our comprehensive database, we can efficiently identify and display the selectors associated with your domain.</p>
            </div>
            <div>
              <h3 className="font-semibold text-lg">How to check your DKIM record effectively?</h3>
              <p className="text-gray-700">To efficiently check your DKIM record, use a reliable DKIM Checker tool by entering your domain name. The tool scans the DNS records for DKIM entries, presenting you with the current configuration details. This process helps in identifying any misconfigurations or the absence of DKIM records.</p>
            </div>
            <div>
              <h3 className="font-semibold text-lg">Why is checking DKIM status important?</h3>
              <p className="text-gray-700">Regularly checking the status of your DKIM record is crucial for maintaining the integrity and reputation of your domain's email communication. It ensures that your emails are authenticated, reducing the risk of them being flagged as spam or phishing attempts by recipients' email servers.</p>
            </div>
            <div>
              <h3 className="font-semibold text-lg">What does a comprehensive DKIM check involve?</h3>
              <p className="text-gray-700">A thorough DKIM check involves verifying the DKIM record's presence, ensuring its correct implementation, and assessing the record's alignment with your domain's email policies. It also includes testing the record's effectiveness in authenticating your emails across different email platforms.</p>
            </div>
            <div>
              <h3 className="font-semibold text-lg">How can DKIM Checker tools benefit email security?</h3>
              <p className="text-gray-700">DKIM Checker tools play a vital role in enhancing email security by validating the authenticity of the emails sent from your domain. By ensuring that emails are properly signed with DKIM, these tools help in preventing email spoofing and securing your email communications against fraudulent activities.</p>
            </div>
          </div>
        </div>
        }
      </div>
    </>
  );
};

export default DKIMLookup;
