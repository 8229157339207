import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HostRecord from '../HostRecord';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QueryForm from '../QueryForm';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { useClerk, useUser } from '@clerk/clerk-react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/features/store/store';
import { setUser, clearUser } from 'src/features/user/userSlice';

interface AuthoritativeDNSProps {}

const AuthoritativeDNS: React.FC<AuthoritativeDNSProps> = () => {
  const { domain } = useParams<{ domain: string }>();
  const [nameServers, setNameServers] = useState<string[]>([]);
  const { openSignIn } = useClerk();
  const { isLoaded, isSignedIn, user } = useUser();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!isLoaded) return;

    if (!isSignedIn) {
      openSignIn({
        redirectUrl: `${window.location.origin}/dns/${domain ?? ''}`,
      });
      return;
    }

    if (user) {
      const clientPrincipal = {
        userId: user.id,
        identityProvider: 'Clerk',
        userDetails: user.primaryEmailAddress?.emailAddress || '',
      };
      dispatch(setUser(clientPrincipal));
    } else {
      dispatch(clearUser());
    }

    const fetchData = async () => {
      if (!domain) return;

      try {
        const response = await fetch('/api/GetAuthoritativeDNS', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ domain }),
        });

        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }

        const jsonResponse = await response.json();
        const nameServers: string[] = jsonResponse.nameServers;
        setNameServers(nameServers);
      } catch (error) {
        console.error('Error fetching DNS data:', error);
      }
    };

    if (domain) fetchData();
  }, [domain, isLoaded, isSignedIn, user, openSignIn, dispatch]);

  const pageTitle = domain
    ? `Authoritative DNS servers for ${domain} - Authoritative DNS Tool | NetQuery Tools`
    : "Authoritative DNS Tool | NetQuery Tools";
  const pageDescription = domain
    ? `View the authoritative DNS servers for ${domain}. Get accurate and up-to-date DNS server information for any domain with NetQuery Tools.`
    : "Easily check the Authoritative DNS servers for your domain with NetQuery Tools. Discover accurate DNS server information for domain analysis and troubleshooting.";
  const keywords = "Authoritative DNS Lookup, DNS Server Lookup, Find DNS Server, DNS Authority Check, Domain DNS Analysis";

  if (!domain) {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="keywords" content={keywords} />
          <link rel="canonical" href={`https://netquery.tools/dns/${domain ? domain : ''}`} />
        </Helmet>
        <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">
              <FontAwesomeIcon icon={faServer} className="text-blue-500" /> Authoritative DNS Lookup
            </h1>
            <p className="mb-4">Discover which DNS servers are authoritative for a domain.</p>
            <QueryForm placeholder="Enter a domain..." ariaLabel="Enter a domain to check for the authoritative DNS servers" navigatePath={(inputValue) => `/dns/${inputValue}`} />
            <div className="faq-section pt-4">
              <h2 className="text-2xl font-bold mb-4">Frequently Asked Questions</h2>
              <div className="mb-4">
                <dt className="font-semibold text-lg mb-2">What is an authoritative DNS server?</dt>
                <dd className="ml-4">
                  An authoritative DNS server is the definitive source for information about domain names it serves, providing the official IP addresses and other DNS records.
                </dd>
              </div>
              <div className="mb-4">
                <dt className="font-semibold text-lg mb-2">How can I find the authoritative DNS servers for a domain?</dt>
                <dd className="ml-4">
                  You can use DNS lookup tools, such as dig or nslookup, specifying the type of record you're interested in (e.g., NS records) to identify a domain's authoritative DNS servers.
                </dd>
              </div>
              <div className="mb-4">
                <dt className="font-semibold text-lg mb-2">Why is it important to know the authoritative DNS servers for a domain?</dt>
                <dd className="ml-4">
                  Understanding which DNS servers are authoritative for a domain can help diagnose DNS-related issues, verify configurations for DNS security features, and understand how domain resolution is handled globally.
                </dd>
              </div>
              <div className="mb-4">
                <dt className="font-semibold text-lg mb-2">What is the difference between authoritative and recursive DNS servers?</dt>
                <dd className="ml-4">
                  Authoritative DNS servers store original DNS records for domains, while recursive DNS servers query these authoritative sources to resolve domain names for clients, caching the results for efficiency.
                </dd>
              </div>
              <div className="mb-4">
                <dt className="font-semibold text-lg mb-2">How do authoritative DNS servers work?</dt>
                <dd className="ml-4">
                  When a query is made for a domain, recursive DNS servers will ask authoritative DNS servers for the record. The authoritative server responds with the requested information, which is then relayed back to the client.
                </dd>
              </div>
              <div className="mb-4">
                <dt className="font-semibold text-lg mb-2">Can a domain have multiple authoritative DNS servers?</dt>
                <dd className="ml-4">
                  Yes, for redundancy and reliability, domains often have multiple authoritative DNS servers, ensuring that if one server is unavailable, others can still provide the necessary DNS information.
                </dd>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={`https://netquery.tools/dns/${domain ? domain : ''}`} />
      </Helmet>
      <div className="bg-white shadow-md rounded-lg p-6 mx-auto my-8">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Authoritative DNS Servers for {domain}</h2>
        <ul className="list-disc list-inside bg-indigo-50 p-4 rounded-md">
          {nameServers.map((ns, index) => (
            <li key={index} className="text-gray-700 py-1"><HostRecord host={ns} /></li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default AuthoritativeDNS;