import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CipherStatusList } from '../../types/CipherStatus';
import { Certificate } from '@common/Certificate';
import CipherTable from './CipherTable';
import CertificateInfo from './CertificateInfo';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faSpinner } from '@fortawesome/free-solid-svg-icons';
import QueryForm from '../QueryForm';
import { useClerk, useUser } from '@clerk/clerk-react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/features/store/store';
import { setUser, clearUser } from 'src/features/user/userSlice';
import IPRecord from '../IPRecord';

const CipherLookup: React.FC = () => {
  const { hostname } = useParams<{ hostname: string }>();
  const { openSignIn } = useClerk();
  const { isLoaded, isSignedIn, user } = useUser();
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<CipherStatusList>([]);
  const [certificate, setCertificates] = useState<Certificate | null>(null);
  const [ipAddress, setIpAddress] = useState<string | null>(null);
  const [dnsServer, setDnsServer] = useState<string[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoaded) return;

    if (!isSignedIn) {
      openSignIn({
        redirectUrl: `${window.location.origin}/cert/${hostname ?? ''}`,
      });
      return;
    }

    if (user) {
      const clientPrincipal = {
        userId: user.id,
        identityProvider: 'Clerk',
        userDetails: user.primaryEmailAddress?.emailAddress || '',
      };
      dispatch(setUser(clientPrincipal));
    } else {
      dispatch(clearUser());
    }

    const fetchData = async () => {
      if (hostname) {
        setIsLoading(true);
        try {
          const response = await fetch('/api/GetCiphers', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ host: hostname }),
          });

          const responseData = await response.json();
          setData(responseData.cipherSuites);
          setIpAddress(responseData.ip);
          setDnsServer(responseData.dnsServer);

          const certResponse = await fetch('/api/GetCertificates', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ host: hostname }),
          });

          const certs = await certResponse.json();
          setCertificates(certs);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    if (hostname) {
      fetchData();
    }
  }, [isLoaded, isSignedIn, user, hostname, openSignIn, dispatch]);

  const pageTitle = hostname
    ? `SSL Certificate and Cipher Suite Details for ${hostname} - SSL Cipher Check Tool`
    : "SSL Cipher Check Tool - Verify SSL Certificates and Cipher Suites Easily";
  const pageDescription =
    "Use our SSL Cipher Check tool to verify the SSL certificate and cipher suites of any website. Ensure your website's SSL security configuration is robust and trustworthy.";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content="SSL Certificate Check, Cipher Suite Analysis, SSL Security, Verify SSL, Cipher Suite Verification, SSL Certificate Validation, HTTPS Security Check, SSL Configuration Check" />
        <link rel="canonical" href={`https://netquery.tools/cert${hostname ? `/${hostname}` : ''}`} />
      </Helmet>
      <div className="container mx-auto p-4 space-y-4">
        {!hostname ? (
          <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faCertificate} className="text-blue-500" /> SSL Certificate and Cipher Suite Check</h1>
              <QueryForm placeholder="Enter a website URL..." ariaLabel="Enter a website URL for SSL certificate and cipher suite check" navigatePath={(inputValue) => `/cert/${inputValue}`} />
              <p className="mt-4 text-md text-gray-600">Use our tool to perform a comprehensive SSL check, verify certificate validity, check TLS versions, and review certificate chains for any website.</p>
              <div className="ssl-faq">
                <h2 className="text-2xl font-bold text-gray-800 mt-8">Frequently Asked Questions about SSL Checks</h2>

                <h3 className="text-xl font-semibold text-gray-800 mt-6">What is an SSL Certificate Check?</h3>
                <p className="text-md text-gray-600 mt-2">
                  An SSL Certificate Check is a process that verifies the validity of a website's SSL certificate, ensuring it is correctly installed, trusted, and not expired. This check helps confirm the website's identity and its ability to encrypt data transferred between the server and the client.
                </p>

                <h3 className="text-xl font-semibold text-gray-800 mt-6">How to Check TLS Version?</h3>
                <p className="text-md text-gray-600 mt-2">
                  To check the TLS version, you can use our SSL Check tool that analyzes the security configuration of the website, including the supported TLS versions. This is crucial for ensuring your website supports the latest, most secure versions of the TLS protocol.
                </p>

                <h3 className="text-xl font-semibold text-gray-800 mt-6">What Happens if My SSL Certificate Expires?</h3>
                <p className="text-md text-gray-600 mt-2">
                  If your SSL certificate expires, browsers will start showing a security warning to visitors, potentially driving them away. It's critical to renew your SSL certificate before its expiration date to maintain website security and trust.
                </p>

                <h3 className="text-xl font-semibold text-gray-800 mt-6">How to Check SSL Certificate Chain?</h3>
                <p className="text-md text-gray-600 mt-2">
                  Checking the SSL certificate chain involves verifying that each certificate in the chain is valid and correctly links back to a trusted root certificate. Our SSL Check tool reviews the certificate chain to ensure there are no issues that could affect the SSL certificate's trustworthiness.
                </p>

                <h3 className="text-xl font-semibold text-gray-800 mt-6">Is SSL Mandatory?</h3>
                <p className="text-md text-gray-600 mt-2">
                  While not technically mandatory, SSL is essential for securing your website, protecting user data, and building trust with your visitors. It's especially critical if your website handles sensitive information like login details or payment information.
                </p>

                <h3 className="text-xl font-semibold text-gray-800 mt-6">Difference Between SSL and Non-SSL?</h3>
                <p className="text-md text-gray-600 mt-2">
                  The main difference lies in the encryption SSL provides. Websites with SSL encrypt the data transmitted between the server and the client, enhancing security and privacy. Non-SSL websites transmit data in plain text, making it vulnerable to interception.
                </p>
              </div>

            </div>
          </div>
        ) : isLoading ? (
          <div className="flex justify-center items-center">
            <FontAwesomeIcon icon={faSpinner} spin className="text-4xl text-blue-500" />
            <p className="ml-2 text-lg">Loading...</p>
          </div>
        ) : (
          <>
            {ipAddress && <p className="text-sm text-gray-600">Tested IP Address: <IPRecord ip={ipAddress} /></p>}
            {dnsServer && (
              <div className="text-sm text-gray-600">
                <p className="font-medium text-gray-800">DNS Server(s):</p>
                <ul className="list-disc list-inside">
                  {dnsServer.map((server, index) => (
                    <li key={index}><IPRecord ip={server} /></li>
                  ))}
                </ul>
              </div>
            )}
            {data?.length > 0 && <CipherTable data={data} />}
            {certificate ? <CertificateInfo certificate={certificate} /> : <p>No certificate information available.</p>}
          </>
        )}
      </div>
    </>
  );
};

export default CipherLookup;
