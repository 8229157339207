import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faFacebookF, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-200 text-gray-800 py-4 border-t border-gray-300">
      <div className="max-w-7xl mx-auto px-4 flex flex-wrap justify-between items-center">
        <div className="flex items-center mb-4 md:mb-0">
          <p className="text-sm">&copy;2024 NetQuery, LLC. All Rights Reserved.</p>
          <nav className="flex ml-4">
            <Link className="text-blue-600 hover:text-blue-800 text-sm mx-2" to="/privacy">Privacy Policy</Link>
            <Link className="text-blue-600 hover:text-blue-800 text-sm mx-2" to="/tos">Terms of Service</Link>
          </nav>
        </div>
        <div className="flex items-center">
          <a href="tel:+16175280549" className="text-blue-600 hover:text-blue-800 text-sm mx-2">
          (617) 528-0549
          </a>
          <a href="https://x.com" className="mx-2">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://linkedin.com" className="mx-2">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://www.facebook.com" className="mx-2">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://www.youtube.com" className="mx-2">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;