import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import QueryForm from '../QueryForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { useClerk, useUser } from '@clerk/clerk-react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/features/store/store';
import { setUser, clearUser } from 'src/features/user/userSlice';

interface ASNRange {
  asn: number;
  ipRange: string;
}

interface ASNToIPProps {}

const ASNToIP: React.FC<ASNToIPProps> = () => {
  const { openSignIn } = useClerk();
  const { isLoaded, isSignedIn, user } = useUser();
  const dispatch = useDispatch<AppDispatch>();
  const { asn } = useParams<{ asn: string }>();
  const [ipRanges, setIPRanges] = useState<ASNRange[]>([]);
  const [totalIPs, setTotalIPs] = useState<number>(0);

  const countIPsFromCIDR = (cidr: string) => {
    const [, prefixLength] = cidr.split('/');
    const maxPrefixLength = cidr.includes(':') ? 128 : 32;
    const hostBits = BigInt(maxPrefixLength) - BigInt(prefixLength);
    return Number(BigInt(2) ** hostBits);
  };

  useEffect(() => {
    if (!isLoaded) return;

    if (!isSignedIn) {
      openSignIn({
        redirectUrl: `${window.location.origin}/asntoip/${asn ?? ''}`,
      });
      return;
    }

    if (user) {
      const clientPrincipal = {
        userId: user.id,
        identityProvider: 'Clerk',
        userDetails: user.primaryEmailAddress?.emailAddress || '',
      };
      dispatch(setUser(clientPrincipal));
    } else {
      dispatch(clearUser());
    }

    const fetchData = async () => {
      if (!asn) {
        console.error("ASN is not defined");
        return;
      }

      try {
        const response = await fetch('/api/GetRangesFromASN', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ asns: [parseInt(asn, 10)] }),
        });

        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }

        const jsonResponse = await response.json();
        const ranges: ASNRange[] = jsonResponse.asnResults[0];
        setIPRanges(ranges);

        const total = ranges.reduce((acc, rangeObj) => acc + countIPsFromCIDR(rangeObj.ipRange), 0);
        setTotalIPs(total);
      } catch (error) {
        console.error('Error fetching IP ranges:', error);
      }
    };

    if (asn) fetchData();
  }, [isLoaded, isSignedIn, user, asn, openSignIn, dispatch]);

  const pageTitle = asn
    ? `Reverse ASN Lookup for ${asn} - Find IP Addresses from ASNs`
    : "Discover IP Details from an ASN - Reverse ASN Lookup Tool";
  const pageDescription = "Quickly find IP addresses associated with any Autonomous System Number (ASN). Ideal for IT professionals, network administrators, and cyber security experts seeking detailed network information.";
  const keywords = "Reverse ASN Lookup, ASN to IP, Autonomous System Number to IP, network mapping, cyber security";

  const helmet = () => (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={`https://netquery.tools/asntoip/${asn || ''}`} />
    </Helmet>
  );

  if (!asn) {
    return (
      <>
        {helmet()}
        <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faExchangeAlt} className="text-blue-500" /> Reverse ASN Lookup Tool</h1>
            <QueryForm placeholder="Enter an ASN..." ariaLabel="Enter an ASN for IP address lookup" navigatePath={(inputValue) => `/asntoip/${inputValue}`} />
            <div className="mt-8">
              <div className="mb-4">
                <h2 className="text-xl font-semibold">What is Reverse ASN Lookup?</h2>
                <p>Reverse ASN Lookup identifies IP address ranges associated with a given Autonomous System Number, aiding in network analysis and cybersecurity efforts.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">How does Reverse ASN Lookup work?</h2>
                <p>By entering an ASN, our tool retrieves all IP addresses mapped to that ASN, providing insights into network distribution and ownership.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">Why is Reverse ASN Lookup important for network security?</h2>
                <p>It helps security analysts track down potentially malicious activities by mapping network boundaries and understanding the scope of specific ASNs.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">Can I find all IPs for a specific ASN?</h2>
                <p>Our tool provides comprehensive listings of IP addresses associated with any given ASN, offering a full view of the network's scope.</p>
              </div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">Are Reverse ASN Lookup results up to date?</h2>
                <p>Yes, our database is regularly updated to ensure you receive the most current information regarding ASN mappings and associated IP addresses.</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {helmet()}
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">IP Ranges for ASN {asn}</h2>
        <p className="mb-4">Total IP addresses in ASN: {totalIPs.toLocaleString()}</p>
        <ul className="list-disc pl-5">
          {ipRanges.length === 0 ? (
            <p className="text-gray-600">No IP ranges found.</p>
          ) : (
            ipRanges.map((rangeObj, index) => (
              <li key={index} className="text-gray-700 bg-gray-100 rounded p-2 mb-2">
                {rangeObj.ipRange}
              </li>
            ))
          )}
        </ul>
      </div>
    </>
  );
};

export default ASNToIP;