import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamationTriangle, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import HostRecord from '../HostRecord';
import { Helmet } from 'react-helmet';
import QueryForm from '../QueryForm';
import { useClerk, useUser } from '@clerk/clerk-react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/features/store/store';
import { setUser, clearUser } from 'src/features/user/userSlice';

interface WhoisLookupProps {}

const WhoisLookup: React.FC<WhoisLookupProps> = () => {
    const { hostname } = useParams<{ hostname: string }>();
    const [whoisData, setWhoisData] = useState<Map<string, string> | null>(null);
    const [rawWhoisData, setRawWhoisData] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { openSignIn } = useClerk();
    const { isLoaded, isSignedIn, user } = useUser();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (!isLoaded) return;

        if (!isSignedIn) {
            openSignIn({
                redirectUrl: `${window.location.origin}/whois/${hostname ?? ''}`,
            });
            return;
        }

        // Dispatch user info to Redux store
        if (user) {
            const clientPrincipal = {
                userId: user.id,
                identityProvider: 'Clerk',
                userDetails: user.primaryEmailAddress?.emailAddress || '',
            };
            dispatch(setUser(clientPrincipal));
        } else {
            dispatch(clearUser());
        }

        const handleWhoisLookup = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch('/api/GetWhois', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ domain: hostname }),
                });

                if (!response.ok) {
                    throw new Error(`Request failed with status ${response.status}`);
                }

                const jsonResponse = await response.json();
                setWhoisData(new Map(jsonResponse.whoisData));
                setRawWhoisData(jsonResponse.rawWhoisData);
            } catch (error) {
                console.error(error);
                setError('An error occurred while fetching data. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        if (hostname) {
            handleWhoisLookup();
        }
    }, [hostname, isLoaded, isSignedIn, user, openSignIn, dispatch]);

    const findKeysWithPattern = (pattern: RegExp): string[] => {
        const matchedKeys: string[] = [];
        whoisData?.forEach((value, key) => {
            if (pattern.test(key)) {
                matchedKeys.push(key);
            }
        });

        matchedKeys.sort((a, b) => {
            const aValue = whoisData?.get(a) || '';
            const bValue = whoisData?.get(b) || '';
            return aValue.localeCompare(bValue);
        });

        return matchedKeys;
    };

    const renderTableSection = (title: string, keys: string[], treatValuesAsHosts: boolean = false) => (
        <tbody className="bg-white divide-y divide-gray-200">
            <tr>
                <td colSpan={2} className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {title}
                </td>
            </tr>
            {keys.map((key) => (
                <tr key={key}>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                        {key}:
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                        {treatValuesAsHosts ? <HostRecord host={whoisData?.get(key) ?? ""} /> : whoisData?.get(key)}
                    </td>
                </tr>
            ))}
        </tbody>
    );

    const pageTitle = hostname ? `Whois Record for ${hostname} - Check Domain Ownership` : "Whois Lookup Tool - Check Whois Records Easily";
    const pageDescription = "Use our Whois Lookup tool to find and analyze Whois records for any domain. Get detailed information on domain ownership, registrar details, and more.";
    const keywords = "Whois Lookup, Domain Ownership, Check Whois Records, Domain Registration Information, Whois Database";

    const helmet = () => (
        <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            <meta name="keywords" content={keywords} />
            <link rel="canonical" href={`https://netquery.tools/whois/${hostname || ''}`} />
        </Helmet>
    );

    if (!hostname) {
        return (
            <>
                {helmet()}
                <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="max-w-4xl mx-auto">
                        <h1 className="text-4xl font-bold text-gray-800 mb-4"><FontAwesomeIcon icon={faUserSecret} className="text-blue-500" /> Discover Whois Records</h1>
                        <QueryForm placeholder="Enter a domain..." ariaLabel="Enter a domain for Whois lookup" navigatePath={(inputValue) => `/whois/${inputValue}`} />
                        <div className="mt-8">
                            <div className="mb-4">
                                <h2 className="text-xl font-semibold">What is a Whois Lookup?</h2>
                                <p>A Whois Lookup is a query and response protocol that is widely used for querying databases that store the registered users or assignees of an Internet resource, such as a domain name.</p>
                            </div>
                            <div className="mb-4">
                                <h2 className="text-xl font-semibold">How can I use the Whois Lookup tool?</h2>
                                <p>Simply enter the domain name you wish to check in the search bar. Our tool will then fetch and display the Whois records, including information about the domain's registration and expiry dates, registrar, registrant information, and more.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            {helmet()}
            <div className="max-w-7xl mt-2 mx-auto sm:px-6 lg:px-8">
                <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 mb-8">
                    Whois Lookup for {hostname}
                </h2>
                {loading && (
                    <div className="flex justify-center items-center">
                        <FontAwesomeIcon icon={faSpinner} spin className="text-4xl text-blue-500" />
                        <span className="ml-3 text-xl text-blue-500">Loading...</span>
                    </div>
                )}
                {error && (
                    <div className="text-red-500 text-sm mt-2">
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                        {error}
                    </div>
                )}
                {whoisData && (
                    <div className="flex flex-col mt-8">
                        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6-lg">
                            <table className="min-w-full">
                                {renderTableSection('Domain Information', [
                                    'Domain Name',
                                    'Registrar',
                                    'Registration Date',
                                    'Expiration Date',
                                    'Updated Date',
                                    'Status',
                                ])}
                                {renderTableSection('Registrant Information', [
                                    'Registrant Name',
                                    'Registrant Organization',
                                    'Registrant Street',
                                    'Registrant City',
                                    'Registrant State/Province',
                                    'Registrant Postal Code',
                                    'Registrant Country',
                                    'Registrant Phone',
                                    'Registrant Email',
                                ])}
                                {renderTableSection('Administrative Contact Information', [
                                    'Admin Name',
                                    'Admin Organization',
                                    'Admin Street',
                                    'Admin City',
                                    'Admin State/Province',
                                    'Admin Postal Code',
                                    'Admin Country',
                                    'Admin Phone',
                                    'Admin Email',
                                ])}
                                {renderTableSection('Technical Contact Information', [
                                    'Tech Name',
                                    'Tech Organization',
                                    'Tech Street',
                                    'Tech City',
                                    'Tech State/Province',
                                    'Tech Postal Code',
                                    'Tech Country',
                                    'Tech Phone',
                                    'Tech Email',
                                ])}
                                {renderTableSection('Name Server Information', findKeysWithPattern(/Name Server \d+/), true)}
                            </table>
                        </div>
                    </div>
                )}
                {rawWhoisData && (
                    <div className="mt-6 p-4 mb-4 bg-white shadow rounded-lg overflow-hidden">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Raw Whois Data</h3>
                        <div className="mt-2 max-w-xl font-mono text-sm text-gray-500">
                            <p>{rawWhoisData}</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default WhoisLookup;