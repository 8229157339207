import { useClerk, useUser } from '@clerk/clerk-react';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch } from 'src/features/store/store';
import { setUser, clearUser } from 'src/features/user/userSlice';

interface TCPPingAttempt {
  attempt: number;
  time: string;
}

interface TCPPingTestResult {
  host: string;
  port: number;
  success: boolean;
  attempts: TCPPingAttempt[];
}

type PingParams = {
  [key: string]: string | undefined;
};

const TCPPing: React.FC = () => {
  const { host, port, retryCount, interval } = useParams<PingParams>();
  const { openSignIn } = useClerk();
  const { isLoaded, isSignedIn, user } = useUser();
  const dispatch = useDispatch<AppDispatch>();

  const [tcpPingResult, setTcpPingResult] = useState<TCPPingTestResult | null>(null);

  useEffect(() => {
    if (!isLoaded) return; // Wait until Clerk is fully loaded

    if (!isSignedIn) {
      let redir = `${window.location.origin}/tcpping/`

      if (host && port && retryCount && interval)
      {
        redir += `${host}/${port}/${retryCount}/${interval}`
      }
      
      openSignIn({
        redirectUrl: redir,
      });
      return;
    }

    // Dispatch user info to Redux store
    if (user) {
      const clientPrincipal = {
        userId: user.id,
        identityProvider: 'Clerk',
        userDetails: user.primaryEmailAddress?.emailAddress || '',
      };
      dispatch(setUser(clientPrincipal));
    } else {
      dispatch(clearUser());
    }

    const fetchData = async () => {
      try {
        if (host && port && retryCount && interval) {
          const response = await fetch('/api/GetTCPPing', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              host,
              port: parseInt(port, 10),
              retryCount: parseInt(retryCount, 10),
              interval: parseInt(interval, 10),
            }),
          });

          if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
          }

          const jsonResponse = await response.json();
          setTcpPingResult(jsonResponse);
        } else {
          console.error('All parameters (host, port, retryCount, interval) are required');
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (host && port && retryCount && interval) {
      fetchData();
    }
  }, [host, port, retryCount, interval, isLoaded, isSignedIn, user, openSignIn, dispatch]);

  return (
    <div className="max-w-2xl mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">TCP Ping Test</h2>
      {tcpPingResult ? (
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-2">Ping Results</h3>
          <p className="font-medium text-gray-600">Host: <span className="font-normal">{tcpPingResult.host}</span></p>
          <p className="font-medium text-gray-600">Port: <span className="font-normal">{tcpPingResult.port}</span></p>
          <p className="font-medium text-gray-600">Success: <span className="font-normal">{tcpPingResult.success ? 'Yes' : 'No'}</span></p>
          <div className="mt-4">
            {tcpPingResult.attempts.map((attempt, index) => (
              <p key={index} className="text-gray-600">Attempt {attempt.attempt}: <span className="font-semibold">{attempt.time}</span></p>
            ))}
          </div>
        </div>
      ) : (
        <p>Loading</p>
      )}
      <h2>Understanding TCP Ping: A Gateway to Network Health</h2>
      <p>In the intricate web of network diagnostics and troubleshooting, TCP Ping emerges as a somewhat unsung hero...</p>
    </div>
  );
};

export default TCPPing;